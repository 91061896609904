import { hasPayment } from '@/api/stripe'

export default class Stripe {
  constructor() {

  }

  hasPayment = (tokenAuth, admissionRequestId) => {
    return hasPayment(tokenAuth, admissionRequestId).then(response => {
      return response;
    }).catch(error => {
      throw error;
    })
  }
}