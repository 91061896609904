import { get } from 'axios'

const host = process.env.VUE_APP_CCAPI
const business = process.env.VUE_APP_BUSINESS_FONCABSA

function hasPayment (token, admissionRequestId) {
  const resource = `/${business}/enroll_payment_fee/has_payment`
  const API_URL = `${host}${resource}`
  
  const config = {
    headers: {
      "Content-Type": "application/json",
    }
  };

  const params = {
    token_auth: token,
    admission_request_id: admissionRequestId
  }
  
  return get(API_URL, { params, config } ) 
}

export {
  hasPayment
}